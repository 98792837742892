import { lazy, Suspense } from "react";

import HdLoader from "@hd/ui-kit/hd-loader/HdLoader";
import HdModal from "@hd/ui-kit/hd-modal/HdModal";

import { useWhatsappModalStore } from "./whatsapp-modal.store";

const WhatsappModalContent = lazy(() => import("./WhatsappModalContent"));

function WhatsappModal() {
  const isOpen = useWhatsappModalStore((state) => state.isOpen);
  const closeModal = useWhatsappModalStore((state) => state.close);

  return (
    <HdModal
      backdrop={true}
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          closeModal();
        }
      }}
      // closeOnClickOutside={false}
      className="p-2 bg-white w-[400px]  flex flex-col"
    >
      <Suspense fallback={<HdLoader></HdLoader>}>
        <WhatsappModalContent></WhatsappModalContent>
      </Suspense>
    </HdModal>
  );
}

export default WhatsappModal;
