import { format, formatRelative, isSameDay, isYesterday } from "date-fns";
import { it } from "date-fns/locale";

export function formatDateFromNow(date: Date) {
  if (isSameDay(date, new Date())) return "Oggi";
  else if (isYesterday(date)) return "Ieri";
  else {
    return format(date, "E dd/LL", { locale: it });
  }
}

export function formatTime(date: Date, seconds = false) {
  return format(date, "HH:mm" + (seconds ? ":ss" : ""), { locale: it });
}

export function formatExtendedDate(date: Date) {
  // domenica 01 gennaio 2022
  return format(date, "eeee dd LLLL y", { locale: it });
}

export function formatDate(date: Date, formatString?: string) {
  if (formatString) return format(date, formatString);
  // domenica 01 gennaio 2022
  return format(date, "dd/MM/y", { locale: it });
}

export function formatDateTime(date: Date, formatString?: string) {
  if (formatString) return format(date, formatString, { locale: it });

  return (
    format(date, "dd/MM/y", { locale: it }) +
    " " +
    format(date, "HH:mm:ss", { locale: it })
  );
}

export function formatRelativeToNow(date: Date) {
  return formatRelative(date, new Date(), { locale: it });
}
