import { PropsWithChildren, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { AuthenticationStatus } from "./types/authentication-status";
import { useAuth } from "./useAuth";

const loginRoute = "/account/login";

export function PrivateRoute(props: PropsWithChildren) {
  const { init, authStatus } = useAuth();

  useEffect(() => {
    if (authStatus === AuthenticationStatus.Loading) {
      init();
    }
  }, [authStatus, init]);

  return authStatus === AuthenticationStatus.Loading ? (
    <></>
  ) : (
    <>
      {authStatus === AuthenticationStatus.Authenticated ? (
        props.children
      ) : (
        <Navigate to={loginRoute} />
      )}
    </>
  );
}
