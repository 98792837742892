import { create } from "zustand";

import { ContactPanelTabIds } from "./types/contact-panel-tab-ids";

export interface ContactPanelState {
  isOpen: boolean;
  isSubPanelOpen: boolean;
  contactId: number | null;
  companyId: number | null;
  selectedTabId: ContactPanelTabIds;
  presetMessage: string | null;
  open(
    contactId: number,
    companyId: number,
    selectedTabId: ContactPanelTabIds | number
  ): void;
  close(): void;
  changeTab(selectedTabId: ContactPanelTabIds): void;
  toggleSubPanel(): void;
  selectPresetMessage(text: string): void;
}

export const useContactPanelStore = create<ContactPanelState>((set) => {
  return {
    isOpen: false,
    isSubPanelOpen: false,
    contactId: null,
    companyId: null,
    selectedTabId: ContactPanelTabIds.CONVERSATION,
    presetMessage: null,
    open: (
      contactId: number,
      companyId: number,
      selectedTabId: ContactPanelTabIds | number
    ) => {
      if (typeof selectedTabId !== "number") {
        set(() => ({
          isOpen: true,
          isSubPanelOpen: false,
          contactId,
          companyId,
          selectedTabId,
        }));
      } else {
        set(() => ({
          isOpen: true,
          isSubPanelOpen: false,
          contactId,
          companyId,
          selectedTabId:
            ContactPanelTabIds[
              Object.keys(ContactPanelTabIds)[
                selectedTabId
              ] as keyof typeof ContactPanelTabIds
            ],
        }));
      }
    },
    toggleSubPanel: () =>
      set((s) => ({ isSubPanelOpen: !s.isSubPanelOpen, presetMessage: null })),
    close: () =>
      set(() => ({
        isOpen: false,
        isSubPanelOpen: false,
        presetMessage: null,
      })),
    changeTab: (selectedTabId: ContactPanelTabIds) =>
      set(() => ({ selectedTabId })),
    selectPresetMessage: (text: string) => set(() => ({ presetMessage: text })),
  };
});
