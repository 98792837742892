import httpClient from "@hd/http-client/http-client";

export interface Params {
  email: string;
}

export const forgotPasswordRequest = async (data: Params) => {
  await httpClient.post<void>(
    import.meta.env.VITE_HD_API_URL + "/auth/forgot-password/" + data.email,
    {}
  );
};
