import loader from "../../assets/loading.gif";

interface HdLoaderProps {
  isFullHeight?: boolean;
  className?: string;
}

function HdLoader({ isFullHeight = true, className }: HdLoaderProps) {
  return (
    <>
      <div
        className={`flex w-full ${
          isFullHeight ? "h-full flex-grow" : ""
        } justify-center items-center ${className}`}
      >
        <img src={loader} />
      </div>
    </>
  );
}

export default HdLoader;
