import "./index.css";

// import "font-awesome/css/font-awesome.min.css";
// import "simple-line-icons/css/simple-line-icons.css";
import { createRoot } from "react-dom/client";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";

import App from "./App.tsx";

function fallbackRender({ error }: FallbackProps) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <ErrorBoundary fallbackRender={fallbackRender}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>
  // </StrictMode>
);
