import httpClient from "@hd/http-client/http-client";

export interface Params {
  newPassword: string;
  token: string;
  name: string;
  surname: string;
}
export const signUpRequest = async (data: Params) => {
  const result = await httpClient.post<void>(
    import.meta.env.VITE_HD_API_URL + "/auth/sign-up",
    data
  );

  return result.data;
};
