import { useNotificationsStore } from "./notifications.store";

export function useNotifications() {
  const toggleOpen = useNotificationsStore((s) => s.toggleOpen);
  const isOpen = useNotificationsStore((s) => s.open);
  const setOpen = useNotificationsStore((s) => s.setOpen);
  const itemsToReadCount = useNotificationsStore(
    (s) => s.items.filter((i) => !i.read).length
  );

  return {
    isOpen,
    itemsToReadCount,
    toggleOpen,
    setOpen,
  };
}
