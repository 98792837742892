import { create } from 'zustand';

import { AuthenticationStatus } from './types/authentication-status';

interface AuthState {
  authStatus: AuthenticationStatus;
  pageCompanyId: number | null;
  error: string;
  setPageCompanyId: (id: number) => void;
  setAuthStatus: (authStatus: AuthenticationStatus) => void;
  setError: (error: string) => void;
}

export const useAuthStore = create<AuthState>()(
  // persist(
  (set) => {
    return {
      authStatus: AuthenticationStatus.Loading,
      error: "",
      pageCompanyId: null,
      setPageCompanyId: (id: number) => {
        set(() => ({ pageCompanyId: id }));
      },
      setAuthStatus: (authStatus: AuthenticationStatus) =>
        set(() => ({ authStatus })),
      setError: (error: string) => set(() => ({ error })),
    };
  }
);
