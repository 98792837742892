import "./lib/i18n/init";

import { I18nProvider, RouterProvider } from "react-aria-components";
import { useHref, useNavigate } from "react-router-dom";

import { ApiError } from "@hd/http-client/types";
import { useLoader } from "@hd/loader/useLoader";
import { showErrorFeedback } from "@hd/toast/toasts";
import ToastWrapper from "@hd/toast/ToastWrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import AppRoutes from "./AppRoutes";
import HdPageLoader from "./ui-kit/hd-page-loader/HdPageLoader";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: ApiError) => {
        if (failureCount < 3) {
          return true;
        }

        showErrorFeedback(error.message);

        return false;
      },
    },
  },
});

function App() {
  // --- Maintenance page ---
  // const hostname = window.location.hostname;
  // const maintenanceHosts = ["secure.hoteldoor.it", "thankful-forest-0ca0b8103.6.azurestaticapps.net"];
  // const inMaintenance = maintenanceHosts.includes(hostname);

  // if (inMaintenance) {
  //   window.location.replace("/maintenance.html");
  //   return null;
  // }
  // ------------------------

  const { active } = useLoader();
  const navigate = useNavigate();

  return (
    <I18nProvider locale="it-IT">
      <RouterProvider navigate={navigate} useHref={useHref}>
        <QueryClientProvider client={queryClient}>
          {active > 0 && <HdPageLoader />}
          <AppRoutes></AppRoutes>
          <ToastWrapper></ToastWrapper>
          {import.meta.env.VITE_SHOW_QUERY_DEVTOOLS == 1 && (
            <div
              style={{
                fontSize: "16px",
              }}
            >
              <ReactQueryDevtools />
            </div>
          )}
        </QueryClientProvider>
      </RouterProvider>
    </I18nProvider>
  );
}

export default App;
