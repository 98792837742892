import { SILENT_REQUEST_HEADER_KEY } from "@hd/http-client/constants";
import httpClient from "@hd/http-client/http-client";
import { RootSectionDto } from "@hd/types/dto/auth/root-section.dto";
import { ListItemDto } from "@hd/types/dto/shared/list-item.dto";
import { useQuery } from "@tanstack/react-query";

const apiUrl = import.meta.env.VITE_HD_API_URL + "/users/me";

const fetchData = async () => {
  const result = await httpClient.get<{
    userId: string;
    username: string;
    userFullName: string;
    activeGroupName: string;
    sections: RootSectionDto[];
    activeGroups: ListItemDto[];
    // activeCompanies: ListItemDto[];
    editUsersAndPermissions: boolean;
  }>(apiUrl, {
    headers: {
      [SILENT_REQUEST_HEADER_KEY]: "true",
    },
  });

  // const section = result.data.sections.find(
  //   (s) => s.sectionTypeName === "Persone"
  // );

  // TODO: mock
  // section?.sections.push({
  //   sectionName: "Hub conversazioni",
  //   sectionTypeName: "Persone",
  //   sectionTypePosition: 1,
  //   sectionTypeIcon: "fa-gear",
  //   sectionUrl: "conversation-hub",
  //   childSections: [],
  //   sectionID: 99999,
  //   sectionPosition: 1,
  //   sectionTypeID: 9999,
  // });

  // teoricamente dovrebbe farlo la API
  return {
    ...result.data,
    sections: result.data.sections
      .filter((s) => s.sectionTypeName !== "Settings")
      .sort((a, b) => (a.sectionTypePosition < b.sectionTypePosition ? -1 : 1))
      .map((r) => ({
        name: r.sectionTypeName,
        defaultCollapsed: r.sectionTypeName === "Sales",
        children: r.sections
          .sort((a, b) => (a.sectionPosition < b.sectionPosition ? -1 : 1))
          .map((s) => ({
            name: s.sectionName,
            path: s.sectionUrl,
            children: s.childSections
              .sort((a, b) => (a.position < b.position ? -1 : 1))
              .map((c) => ({
                name: c.name,
                path: c.url,
              })),
          })),
      })),
  };
};

export const meQueryKey = () => [apiUrl];

export const useMeQuery = () => {
  return useQuery({
    staleTime: 1000 * 60 * 30, // 30 minuti
    queryKey: meQueryKey(),
    queryFn: () => fetchData(),
  });
};
