import { useLoaderStore } from "./loader.store";

export function useLoader() {
  const active = useLoaderStore((s) => s.active);
  const addLoader = useLoaderStore((s) => s.addLoader);
  const removeLoader = useLoaderStore((s) => s.removeLoader);

  return {
    active,
    addLoader,
    removeLoader,
  };
}
