import { useCallback, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useScript } from "usehooks-ts";

import { useMeQuery } from "@hd/api/auth/queries/useMeQuery";
import { useAuth } from "@hd/auth/useAuth";
import ContactPanel from "@hd/contact-panel/ContactPanel";
import NewConversationModal from "@hd/new-conversation-modal/NewConversationModal";
import HdLoader from "@hd/ui-kit/hd-loader/HdLoader";
import WhatsappModal from "@hd/whatsapp-modal/WhatsappModal";
import { useQueryClient } from "@tanstack/react-query";

import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";

function Layout() {
  const { logout, changeGroup } = useAuth();
  const { data: userData, isPending: userDataLoading } = useMeQuery();

  const channelRef = useRef<BroadcastChannel | null>(null);
  const queryClient = useQueryClient();

  useScript(`//js-eu1.hs-scripts.com/25916624.js`, {
    removeOnUnmount: false,
    id: "hs-script-loader",
  });

  useEffect(() => {
    const channel = new BroadcastChannel("change-group-channel");

    channel.onmessage = () => {
      queryClient.resetQueries();
    };

    channelRef.current = channel;

    return () => {
      channel.close();
    };
  }, [queryClient]);

  const changeGroupHandler = useCallback(
    async (id: number) => {
      await changeGroup(id);
      // await refreshToken();
      // await queryClient.invalidateQueries({
      //   queryKey: meQueryKey(),
      // });
      await queryClient.resetQueries();
      window.postMessage("reloadframe", "*");
      channelRef.current?.postMessage(id);
    },
    [changeGroup, queryClient]
  );

  return userDataLoading || !userData ? (
    <HdLoader></HdLoader>
  ) : (
    <div className="flex flex-col h-full flex-grow" style={{ minWidth: 1024 }}>
      <Header
        username={userData.username}
        userId={userData.userId}
        // activeCompanies={userData.activeCompanies}
        activeGroupName={userData.activeGroupName}
        activeGroups={userData.activeGroups}
        editUsersAndPermissions={userData.editUsersAndPermissions}
        logout={() => {
          logout();
        }}
        changeGroup={changeGroupHandler}
      />
      <div className="flex flex-grow relative">
        <Sidebar items={userData.sections} />
        <div className="flex-grow bg-bodyBg flex flex-col overflow-hidden">
          <Outlet></Outlet>
        </div>
      </div>
      {/* <Footer></Footer> */}
      <ContactPanel
        loggedUserName={userData.username}
        loggedUserFullName={userData.userFullName}
      ></ContactPanel>
      <WhatsappModal></WhatsappModal>
      <NewConversationModal></NewConversationModal>
    </div>
  );
}

export default Layout;
