import { useWhatsappModalStore } from "./whatsapp-modal.store";

export function useWhatsappModal() {
  const open = useWhatsappModalStore((s) => s.open);
  const close = useWhatsappModalStore((s) => s.close);

  return {
    open,
    close,
  };
}
