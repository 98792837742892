import { useContactPanelStore } from "./contact-panel.store";

export function useContactPanel() {
  const open = useContactPanelStore((s) => s.open);
  const close = useContactPanelStore((s) => s.close);
  return {
    open,
    close,
  };
}
