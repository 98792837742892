import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useContactPanel } from "@hd/contact-panel/useContactPanel";
import { showErrorFeedback } from "@hd/toast/toasts";
import HdPageLoader from "@hd/ui-kit/hd-page-loader/HdPageLoader";
import { useWhatsappModal } from "@hd/whatsapp-modal/useWhatsappModal";

import { useSidebar } from "../layout/sidebar/sidebar.store";

interface IFrameMessage {
  kind: string;
}

interface LoadedMessage extends IFrameMessage {
  kind: "loaded";
  url: string;
  height: number;
  title: string;
}

interface OpenContactPanelMessage extends IFrameMessage {
  kind: "showContactPanel";
  contactId: string;
  companyId: string;
  tabIndex: number;
}

interface OpenWhatsappModalMessage extends IFrameMessage {
  kind: "openWhatsappModal";
  contactId: string;
  quotationId: string | null;
}

interface OpenConversationHubMessage extends IFrameMessage {
  kind: "openConversationHub";
  companyId: number;
}

function LegacyHdBridgePage() {
  const location = useLocation();
  const navigate = useNavigate();

  // const setUrl = useLegacyBridge((s) => s.setUrl);
  const [loading, setLoading] = useState(true);

  const [iframeState, setIframeState] = useState(0);

  const currentUrl = useRef("");

  const timeoutTimer = useRef<number | null>(null);

  const { setCurrentUrl: setUrl } = useSidebar();

  const { open: openContactPanel } = useContactPanel();
  const { open: openWhatsappModal } = useWhatsappModal();

  const prevRouterUrl = useRef("");

  useEffect(() => {
    const routerUrl = location.pathname + location.hash + location.search;
    if (
      !!currentUrl.current &&
      routerUrl !== currentUrl.current &&
      prevRouterUrl.current === routerUrl
    ) {
      setLoading(true);
      setIframeState((prev) => prev + 1);
    } else {
      prevRouterUrl.current = routerUrl;
    }
  }, [location, navigate]);

  const onMessageReceived = useCallback(
    (data: IFrameMessage) => {
      if (data.kind === "loading") {
        setLoading(true);
        // if (!loading) {
        //   console.log("loading changed, triggering rendering");
        // } else {
        //   console.log("loading not changed, should not trigger rendering");
        // }
      } else if (data.kind === "loaded") {
        const message = data as LoadedMessage;
        setLoading(false);

        setUrl(message.url as string);
        document.title = message.title;
        window.history.replaceState(
          null,
          message.title,
          window.location.origin + message.url
        );
        // navigate(message.url, { replace: true });
        currentUrl.current = message.url;
      } else if (data.kind === "showContactPanel") {
        // console.log("showContactPanel");

        const message = data as OpenContactPanelMessage;

        openContactPanel(
          Number(message.contactId),
          Number(message.companyId),
          message.tabIndex
        );
      } else if (data.kind === "openWhatsappModal") {
        // console.log("showContactPanel");

        const message = data as OpenWhatsappModalMessage;

        openWhatsappModal(Number(message.contactId), message.quotationId);
      } else if (data.kind === "openConversationHub") {
        const message = data as OpenConversationHubMessage;
        navigate(`/conversation-hub?companyId=${message.companyId}`);
      }
    },
    [setLoading, setUrl, openContactPanel, openWhatsappModal, navigate]
  );

  useEffect(() => {
    const handler = (e: MessageEvent) => {
      if (e.data === "reloadframe") {
        setLoading(true);
        setIframeState((prev) => prev + 1);
      }
    };
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [setLoading]);

  useEffect(() => {
    if (loading) {
      timeoutTimer.current = window.setTimeout(() => {
        if (loading) {
          setLoading(false);

          showErrorFeedback("Si è verificato un errore durante il caricamento");
        }
      }, 50000);
    } else {
      if (timeoutTimer.current) {
        clearTimeout(timeoutTimer.current);
        timeoutTimer.current = null;
      }
    }
  }, [loading, setLoading]);

  useEffect(() => {
    function messageHandler(event: MessageEvent<IFrameMessage>) {
      if (event.origin === import.meta.env.VITE_HD_LEGACY_URL) {
        onMessageReceived(event.data);
      }
    }

    // run useEffect only once
    // console.log("running effect! Add event listener");
    addEventListener("message", messageHandler);

    return () => {
      // console.log("remove event listener");
      removeEventListener("message", messageHandler);
    };
  }, [onMessageReceived]);

  return (
    <>
      {loading && <HdPageLoader></HdPageLoader>}
      <iframe
        width="100%"
        style={{ minHeight: "100%" }}
        referrerPolicy="origin"
        key={iframeState}
        src={
          import.meta.env.VITE_HD_LEGACY_URL +
          location.pathname +
          location.hash +
          location.search
        }
        allow="clipboard-read; clipboard-write"
      ></iframe>
    </>
  );
}

export default LegacyHdBridgePage;
