// eslint-disable-next-line @dword-design/import-alias/prefer-alias
import HdLoader from "../hd-loader/HdLoader";

function HdPageLoader() {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full z-20 bg-white bg-opacity-50"></div>
      <div className="fixed flex top-0 left-0 w-full h-full justify-center items-center z-30">
        <div className="flex w-full justify-center items-center">
          <HdLoader></HdLoader>
        </div>
      </div>
    </>
  );
}

export default HdPageLoader;
