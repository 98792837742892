import { SILENT_REQUEST_HEADER_KEY } from "@hd/http-client/constants";
import httpClient from "@hd/http-client/http-client";
import { NoticeDto } from "@hd/types/dto/notice/notice.dto";
import { useQuery } from "@tanstack/react-query";

const apiUrl = () => `/notice`;

const fetchData = async (): Promise<NoticeDto[]> => {
  const result = await httpClient.get<NoticeDto[]>(
    import.meta.env.VITE_HD_API_URL + apiUrl(),
    { headers: { [SILENT_REQUEST_HEADER_KEY]: "true" } }
  );

  return result.data;
};

export const noticeQueryKey = () => [apiUrl()];

export const useLastNoticeQuery = () => {
  return useQuery({
    refetchInterval: 1000 * 60 * 5, // 5 minuti
    queryKey: noticeQueryKey(),
    queryFn: () => fetchData(),
  });
};
