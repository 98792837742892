import { SILENT_REQUEST_HEADER_KEY } from "@hd/http-client/constants";
import httpClient from "@hd/http-client/http-client";
import { ListItemDto } from "@hd/types/dto/shared/list-item.dto";
import { SectionIds } from "@hd/types/shared/section-ids";
import { useQuery } from "@tanstack/react-query";

type QueryParams = {
  sectionId: SectionIds;
};

const apiUrl = (params: QueryParams) =>
  `/company/section-companies/${params.sectionId}`;

const fetchData = async (params: QueryParams): Promise<ListItemDto[]> => {
  const result = await httpClient.get<ListItemDto[]>(
    import.meta.env.VITE_HD_API_URL + apiUrl(params),
    { headers: { [SILENT_REQUEST_HEADER_KEY]: "true" } }
  );

  return result.data;
};

export const companiesForNotificationsQueryKey = (params: QueryParams) => [
  apiUrl(params),
];

export const useSectionCompaniesQuery = (params: QueryParams) => {
  return useQuery({
    queryKey: companiesForNotificationsQueryKey(params),
    queryFn: () => fetchData(params),
  });
};
