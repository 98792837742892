import { lazy, Suspense } from "react";
import { Route } from "react-router-dom";

import HdPageLoader from "@hd/ui-kit/hd-page-loader/HdPageLoader";

const ConciergeDashboardPage = lazy(
  () => import("./pages/concierge-dashboard/ConciergeDashboardPage")
);
function useConciergeRoutes() {
  return (
    <Route
      path="/concierge-dashboard"
      element={
        <Suspense fallback={<HdPageLoader />}>
          <ConciergeDashboardPage />
        </Suspense>
      }
    />
  );
}

export default useConciergeRoutes;
