import { lazy, Suspense } from "react";

import HdLoader from "@hd/ui-kit/hd-loader/HdLoader";
import HdOffcanvas from "@hd/ui-kit/hd-offcanvas/HdOffcanvas";

// import DefaultMessagesPanel from "./components/DefaultMessagesPanel";
import { useContactPanelStore } from "./contact-panel.store";

interface ContactPanelProps {
  loggedUserName: string;
  loggedUserFullName: string;
}

const ContactPanelContent = lazy(
  () => import("./components/ContactPanelContent")
);

function ContactPanel({
  loggedUserName,
  loggedUserFullName,
}: ContactPanelProps) {
  const isOpen = useContactPanelStore((state) => state.isOpen);
  const close = useContactPanelStore((state) => state.close);

  return (
    <HdOffcanvas
      isOpen={isOpen}
      position="right"
      onClose={close}
      backdrop={true}
    >
      <div
        className="flex h-full text-bodyText text-xs relative bg-white"
        style={{ width: "800px" }}
      >
        <Suspense fallback={<HdLoader></HdLoader>}>
          <ContactPanelContent
            loggedUserName={loggedUserName}
            loggedUserFullName={loggedUserFullName}
          ></ContactPanelContent>
        </Suspense>
      </div>
    </HdOffcanvas>
  );
}

export default ContactPanel;
