import { SILENT_REQUEST_HEADER_KEY } from "@hd/http-client/constants";
import httpClient from "@hd/http-client/http-client";

import { AuthenticationResultDto } from "../../../types/dto/auth/authentication-result.dto";

export const REFRESH_TOKEN_URL =
  import.meta.env.VITE_HD_API_URL + "/auth/refresh-token";
export const refreshTokenRequest = async () => {
  const result = await httpClient.post<AuthenticationResultDto>(
    REFRESH_TOKEN_URL,
    {
      [SILENT_REQUEST_HEADER_KEY]: true,
    }
  );

  return result.data;
};
