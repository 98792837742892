import { useCallback } from "react";

import { useChangeGroupMutation } from "@hd/api/auth/mutations/useChangeGroupMutation";
import { useForgotPasswordMutation } from "@hd/api/auth/mutations/useForgotPasswordMutation";
import { useLoginMutation } from "@hd/api/auth/mutations/useLoginMutation";
import { useLogoutMutation } from "@hd/api/auth/mutations/useLogoutMutation";
import { useResetPasswordMutation } from "@hd/api/auth/mutations/useResetPasswordMutation";
import { meQueryKey } from "@hd/api/auth/queries/useMeQuery";
import { refreshTokenRequest } from "@hd/api/auth/requests/refresh-token-request";
import { signUpRequest } from "@hd/api/auth/requests/signup-request";
import { ApiError } from "@hd/http-client/types";
import { useTranslate } from "@hd/i18n/useTranslate";
import { showErrorFeedback } from "@hd/toast/toasts";
import { useQueryClient } from "@tanstack/react-query";

import { useAuthStore } from "./auth.store";
import { AuthenticationStatus } from "./types/authentication-status";

// Queste funzioni non dipendono da altri hook, per cui possono essere portate fuori dall'hook per evitare
// la creazione di nuove istanze ad ogni render
const setToken = (token: string | null) => {
  if (token) {
    localStorage.setItem("token", token);
  } else {
    localStorage.removeItem("token");
  }
};
const getToken = () => {
  return localStorage.getItem("token");
};
const refreshToken = async () => {
  const result = await refreshTokenRequest();
  setToken(result.jwtToken);
};

/**
 * Hook per la gestione dell'autenticazione.
 *
 * @returns un oggetto contenente le seguenti proprietà:
 *
 * - `pageCompanyId`: il companyId dell'azienda correntemente selezionata
 * - `setPageCompanyId`: la funzione per cambiare l'azienda correntemente selezionata
 * - `error`: l'eventuale errore verificatosi durante l'autenticazione
 * - `authStatus`: lo stato corrente dell'autenticazione
 * - `logout`: la funzione per effettuare la logout
 * - `login`: la funzione per effettuare la login
 * - `init`: la funzione per inizializzare l'autenticazione
 * - `changeGroup`: la funzione per cambiare l'azienda selezionata
 * - `setToken`: la funzione per impostare il token di autenticazione
 * - `getToken`: la funzione per ottenere il token di autenticazione
 * - `refreshToken`: la funzione per rinnovare il token di autenticazione
 * - `sessionExpired`: la funzione per gestire l'eventuale scadenza della sessione
 * - `forgotPassword`: la funzione per richiedere la reimpostazione della password
 * - `resetPassword`: la funzione per reimpostare la password
 */

export const useAuth = () => {
  const pageCompanyId = useAuthStore((s) => s.pageCompanyId);
  const error = useAuthStore((s) => s.error);
  const authStatus = useAuthStore((s) => s.authStatus);
  const setAuthStatus = useAuthStore((s) => s.setAuthStatus);
  const setPageCompanyId = useAuthStore((s) => s.setPageCompanyId);
  const setError = useAuthStore((s) => s.setError);

  // ATTENZIONE! NECESSARIO FARE DESTRUCTURING E METTERE IN USECALLBACK SOLO LA MUTATEASYNC perchè altrimenti l'oggetto ritornato
  // dalla useMutation cambia ad ogni istanza causando il cambio delle useCallback (loop se sono usate in useEffect).
  // Hanno senso le mutation in un contesto globale al di fuori di un componente? Non molto, l'unico valore aggiunto è probabilmente
  // il retry gratuito e aver a disposizione dei flag per sapere lo stato della request
  const { mutateAsync: logoutMutationAsync } = useLogoutMutation();
  const {
    mutateAsync: loginMutationAsync,
    // isPending: loginIsPending,
    // isSuccess: loginIsSuccess,
  } = useLoginMutation();

  const { mutateAsync: forgotPasswordMutationAsync } =
    useForgotPasswordMutation();
  const { mutateAsync: resetPasswordMutationAsync } =
    useResetPasswordMutation();
  const { mutateAsync: changeGroupMutationAsync } = useChangeGroupMutation();

  const queryClient = useQueryClient();
  const t = useTranslate();

  const init = useCallback(async () => {
    try {
      const result = await refreshTokenRequest();
      setToken(result.jwtToken);
      setAuthStatus(AuthenticationStatus.Authenticated);
    } catch {
      setToken(null);
      setAuthStatus(AuthenticationStatus.Unauthenticated);
    }
  }, [setAuthStatus]);

  const sessionExpired = useCallback(async () => {
    setAuthStatus(AuthenticationStatus.Unauthenticated);
    showErrorFeedback("Session expired");
  }, [setAuthStatus]);

  const logout = useCallback(async () => {
    try {
      await logoutMutationAsync();
    } finally {
      setAuthStatus(AuthenticationStatus.Unauthenticated);
      setToken(null);
      setError("");

      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: meQueryKey(),
        });
      });
    }
  }, [logoutMutationAsync, setAuthStatus, setError, queryClient]);

  const login = useCallback(
    async (username: string, password: string) => {
      try {
        const result = await loginMutationAsync({ username, password });
        setToken(result.jwtToken);
        setAuthStatus(AuthenticationStatus.Authenticated);
        setError("");
      } catch (error) {
        setToken(null);
        setAuthStatus(AuthenticationStatus.Unauthenticated);
        setError(
          error instanceof ApiError ? error.message : t("common.genericError")
        );
      }
    },
    [loginMutationAsync, setAuthStatus, setError, t]
  );

  const forgotPassword = useCallback(
    async (email: string) => {
      try {
        await forgotPasswordMutationAsync({
          email,
        });
        setError("");
        return true;
      } catch (error) {
        setToken(null);
        setAuthStatus(AuthenticationStatus.Unauthenticated);
        setError(
          error instanceof ApiError ? error.message : t("common.genericError")
        );
        return false;
      }
    },
    [forgotPasswordMutationAsync, setAuthStatus, setError, t]
  );

  const resetPassword = useCallback(
    async (token: string, newPassword: string) => {
      try {
        await resetPasswordMutationAsync({
          newPassword,
          token,
        });
        setError("");
        return true;
      } catch (error) {
        setToken(null);
        setAuthStatus(AuthenticationStatus.Unauthenticated);
        setError(
          error instanceof ApiError ? error.message : t("common.genericError")
        );
        return false;
      }
    },
    [resetPasswordMutationAsync, setAuthStatus, setError, t]
  );

  const changeGroup = useCallback(
    async (groupId: number) => {
      try {
        const result = await changeGroupMutationAsync({
          groupId,
        });
        setToken(result.jwtToken);
      } catch (error) {
        showErrorFeedback(
          error instanceof ApiError ? error.message : t("common.genericError")
        );
      }
    },
    [changeGroupMutationAsync, t]
  );

  const signUp = useCallback(
    async (
      token: string,
      newPassword: string,
      name: string,
      surname: string
    ) => {
      try {
        await signUpRequest({
          newPassword,
          token,
          name,
          surname,
        });
        setError("");
        return true;
      } catch (error) {
        setToken(null);
        setAuthStatus(AuthenticationStatus.Unauthenticated);
        setError(
          error instanceof ApiError ? error.message : t("common.genericError")
        );
        return false;
      }
    },
    [setAuthStatus, setError, t]
  );

  return {
    pageCompanyId,
    setPageCompanyId,
    error,
    setError,
    authStatus,
    logout,
    login,
    // loginIsPending,
    // loginIsSuccess,
    init,
    changeGroup,
    setToken,
    getToken,
    refreshToken,
    sessionExpired,
    forgotPassword,
    resetPassword,
    signUp,
  };
};
