import httpClient from "@hd/http-client/http-client";

interface Params {
  username: string;
  password: string;
}
export const loginRequest = async (data: Params) => {
  const loginResult = await httpClient.post<{
    username: string;
    jwtToken: string;
  }>(import.meta.env.VITE_HD_API_URL + "/auth/authenticate", data, {
    // headers: {
    //   [SILENT_REQUEST_HEADER_KEY]: "true",
    // },
  });

  return { jwtToken: loginResult.data.jwtToken };
};
