export class ApiError extends Error {
  constructor(name?: string, message?: string, status?: number) {
    super(message);
    this.name = name || "ApiError";
    this.status = status;
  }
  status?: number;
}

export interface AxiosAuthRefreshCache {
  // skipInstances: AxiosInstance[];
  refreshCall: Promise<unknown> | undefined;
  requestQueueInterceptorId: number | undefined;
}
