import httpClient from "@hd/http-client/http-client";

export interface Params {
  newPassword: string;
  token: string;
}

export const resetPasswordRequest = async (params: Params) => {
  await httpClient.post<void>(
    import.meta.env.VITE_HD_API_URL + "/auth/reset-password/",
    params
  );
};
