const useLogger = () => {
  const debug = console.debug;
  const error = console.error;

  return {
    debug,
    error,
  };
};

export default useLogger;
