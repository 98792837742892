import axios from "axios";
import { useEffect, useRef } from "react";

import { useTranslate } from "@hd/i18n/useTranslate";
import useLogger from "@hd/logger/useLogger";
import { ContactConnectionInfoDto } from "@hd/types/dto/contact/contact-connection-info.dto";
import { SignalrChatMessageDto } from "@hd/types/dto/contact/signalr-chat-message.dto";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

import { ChatRoomStatus } from "./chat-room-status";

export interface ChatConnection {
  connection: HubConnection;
  connect: () => Promise<string>;
  disconnect: () => Promise<string>;
  onConnected: (cb: (hubName: string) => void) => void;
  onDisconnected: (cb: (hubName: string) => void) => void;
  onReconnecting: (cb: (hubName: string) => void) => void;
  onNewMessage: (cb: (message: SignalrChatMessageDto) => void) => void;
  onReadMessages: (cb: (messageIds: SignalrChatMessageDto) => void) => void;
  onPersonStatusUpdated: (
    cb: (message: ContactConnectionInfoDto) => void
  ) => void;
}

export default function useHubConnection(
  companyId: number | null,
  username: string | null,
  onNewMessage?: (message: SignalrChatMessageDto) => void,
  onPersonStatusUpdated?: (message: ContactConnectionInfoDto) => void,
  onStatusChanged?: (status: ChatRoomStatus) => void,
  onReadMessages?: (messageIds: string[]) => void
) {
  const { debug, error } = useLogger();
  const t = useTranslate();

  const onNewMessageRef = useRef(onNewMessage);
  const onPersonStatusUpdatedRef = useRef(onPersonStatusUpdated);
  const onStatusChangedRef = useRef(onStatusChanged);
  const onReadMessagesRef = useRef(onReadMessages);

  useEffect(() => {
    onNewMessageRef.current = onNewMessage;
    onPersonStatusUpdatedRef.current = onPersonStatusUpdated;
    onStatusChangedRef.current = onStatusChanged;
    onReadMessagesRef.current = onReadMessages;
  }, [onNewMessage, onPersonStatusUpdated, onStatusChanged, onReadMessages]);

  useEffect(() => {
    if (companyId && username) {
      const hubName = `c${companyId}`;
      const userId = `${companyId}~${username}~${1}`;

      const connection = new HubConnectionBuilder()
        .withUrl(
          import.meta.env.VITE_HD_CHAT_OPERATOR_URL +
            `/api?userId=${userId}&hub=${hubName}`
        )
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      const startConnection = async () => {
        try {
          // if (connection.state === "Connected") return;
          await connection.start();

          await axios.post(
            `${
              import.meta.env.VITE_HD_CHAT_OPERATOR_URL
            }/api/operators/join?userId=${userId}&hub=${hubName}`
          );
        } catch (e) {
          error(e);
          // showErrorFeedback(t("common.genericError"));
        }

        connection.on("newMessage", (message: SignalrChatMessageDto) => {
          onNewMessageRef.current?.(message);
        });

        connection.on(
          "personStatusUpdated",
          (message: ContactConnectionInfoDto) => {
            onPersonStatusUpdatedRef.current?.(message);
          }
        );

        connection.on("readMessages", (readMessages: string[]) => {
          onReadMessagesRef.current?.(readMessages);
        });

        onStatusChangedRef.current?.(ChatRoomStatus.Connected);

        connection.onreconnected(() =>
          onStatusChangedRef.current?.(ChatRoomStatus.Connected)
        );
        connection.onreconnecting(() =>
          onStatusChangedRef.current?.(ChatRoomStatus.Connecting)
        );
        connection.onclose(() =>
          onStatusChangedRef.current?.(ChatRoomStatus.Disconnected)
        );

        return hubName;
      };

      const stopConnection = async () => {
        await connection.stop();

        return hubName;
      };

      // let onNewMsgCb = (message: SignalrChatMessage) => {};

      // const _chatConn: ChatConnection = {
      //   connection: connection,
      //   connect: startConnection,
      //   disconnect: stopConnection,
      //   onConnected: (cb: (hubName: string) => void) => {
      //     onStatusChanged?.(ChatRoomStatus.Connected);
      //     if (onNewMessage) {
      //       connection.on("newMessage", onNewMessage);
      //     }
      //     if (onPersonStatusUpdated) {
      //       connection.on("personStatusUpdated", onPersonStatusUpdated);
      //     }
      //     if (onStatusChanged) {
      //       connection.onreconnected(() =>
      //         onStatusChanged(ChatRoomStatus.Connected)
      //       );
      //       connection.onreconnecting(() =>
      //         onStatusChanged(ChatRoomStatus.Connecting)
      //       );
      //       connection.onclose(() =>
      //         onStatusChanged(ChatRoomStatus.Disconnected)
      //       );
      //     }
      //   },
      //   onDisconnected: (cb: (hubName: string) => void) => {
      //     connection.off("newMessage");
      //     connection.off("personStatusUpdated");
      //     connection.onclose(() => cb(hubName));
      //   },
      //   onReconnecting: (cb: (hubName: string) => void) => {
      //     connection.onreconnecting(() => cb(hubName));
      //   },
      //   onNewMessage: (cb: (message: SignalrChatMessage) => void) => {
      //     connection.on("newMessage", (message: SignalrChatMessage) => {
      //       cb(message);
      //     });
      //   },
      //   onPersonStatusUpdated: (
      //     cb: (message: ContactConnectionInfo) => void
      //   ) => {
      //     connection.on(
      //       "personStatusUpdated",
      //       (message: ContactConnectionInfo) => {
      //         cb(message);
      //       }
      //     );
      //   },
      // };

      // setChatConnection(_chatConn);

      startConnection().then((hubName) => {
        debug("connected", hubName);
      });

      return () => {
        stopConnection().then((hubName) => {
          debug("disconnected", hubName);
        });
      };
    }
  }, [companyId, username, debug, t, error]);

  // return chatConnection;
}
