import { create } from "zustand";

export interface LoaderState {
  active: number;
  addLoader(): void;
  removeLoader(): void;
}

export const useLoaderStore = create<LoaderState>((set) => {
  return {
    active: 0,
    addLoader: () => {
      set((state) => ({
        active: state.active + 1,
      }));
    },
    removeLoader: () => {
      set((state) => ({
        active: state.active > 0 ? state.active - 1 : 0,
      }));
    },
  };
});
