import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { ContactFilters } from "./types/contact-filters";

interface NewConversationModalState {
  isOpen: boolean;
  step: number;
  companyId: number | null;
  filters: ContactFilters | null;
  message: { [languageId: number]: string };
  excludedRecipientsIds: string[];
  showContactList: boolean;

  open: (companyId: number, step: number, filters?: ContactFilters) => void;
  setFilters: (filters: ContactFilters) => void;
  setStep: (step: number) => void;
  setMessage: (languageId: number, message: string) => void;
  close: () => void;
  setShowContactList: (show: boolean) => void;
  setExcludedRecipientIds: (recipientsIds: string[]) => void;
}

export const useNewConversationModalStore = create<NewConversationModalState>()(
  devtools((set) => {
    return {
      isOpen: false,
      step: 0,
      companyId: null,
      filters: null,
      message: {},
      excludedRecipientsIds: [],
      showContactList: false,
      open: (companyId, step, filters) => {
        set(() => ({
          isOpen: true,
          step,
          companyId,
          filters: filters || null,
        }));
      },
      setFilters: (filters) => {
        set(() => ({
          filters,
        }));
      },
      setStep: (step) => {
        set(() => ({
          step,
        }));
      },
      setMessage: (languageId, message) => {
        set((s) => ({
          message: { ...s.message, [languageId]: message },
        }));
      },
      close: () => {
        set(() => ({
          isOpen: false,
          step: 0,
          filters: null,
          companyId: null,
          message: {},
          excludedRecipientsIds: [],
        }));
      },
      setShowContactList: (show) => {
        set(() => ({
          showContactList: show,
        }));
      },
      setExcludedRecipientIds: (recipientsIds: string[]) => {
        set(() => ({
          excludedRecipientsIds: recipientsIds,
          message: {},
        }));
      },
    };
  })
);
