import clsx from "clsx";
import React from "react";

export interface HdTextInputProps {
  className?: string;
  name?: string;
  type: "text" | "password" | "email";
  placeholder?: string;
  value?: string;
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => void;
  disabled?: boolean;
  multiline?: boolean;
  icon?: React.ReactNode;
}

function HdTextInput(props: HdTextInputProps) {
  return props.multiline ? (
    <textarea
      {...props}
      className={`border border-gray-300 text-bodyText px-3 py-2 outline-none transition-all duration-150 ease-in-out focus:border-gray-400 ${
        props.className || ""
      }`}
    ></textarea>
  ) : (
    <div className="relative">
      <input
        {...props}
        className={clsx(
          "border w-full border-gray-300 text-bodyText px-3 py-2 outline-none transition-all duration-150 ease-in-out focus:border-gray-400",
          props.className,
          props.icon ? "pr-7" : ""
        )}
      />
      {props.icon && (
        <div className="absolute right-3 top-1/2 -translate-y-1/2">
          {props.icon}
        </div>
      )}
    </div>
  );
}

export default HdTextInput;
