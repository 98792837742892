import { create } from "zustand";
import { persist } from "zustand/middleware";

import { t } from "@hd/i18n/t";
import { showNewNotification } from "@hd/toast/toasts";
import { SignalrChatMessageDto } from "@hd/types/dto/contact/signalr-chat-message.dto";

export interface Notification {
  type: "message" | "connection-info";
  content: SignalrChatMessageDto;
  date: Date;
  read: boolean;
  companyName: string;
  companyId: number;
}

export interface NotificationsState {
  open: boolean;
  items: Notification[];
  companyId: number | null;
  push: (notification: Notification) => void;
  toggleOpen: () => void;
  setOpen: (value: boolean) => void;
  setReadAll: () => void;
  setRead: (ids: string[]) => void;
  setCompanyId(companyId: number): void;
}

export const useNotificationsStore = create<NotificationsState>()(
  persist(
    (set) => ({
      open: false,
      items: [],
      companyId: null,

      push: (notification: Notification) => {
        set((state) => ({
          items: [...state.items, notification],
        }));

        if (notification.type === "message") {
          const content = notification.content as SignalrChatMessageDto;
          showNewNotification(
            t("common.newMessageFrom", {
              sender: content.SenderFullName,
            })
          );
        }
      },

      setReadAll: () => {
        set((state) => ({
          items: state.items.map((item) => ({ ...item, read: true })),
        }));
      },

      setRead: (ids: string[]) => {
        set((state) => ({
          items: state.items.map((item) =>
            ids.includes(item.content.Id) ? { ...item, read: true } : item
          ),
        }));
      },

      toggleOpen: () => {
        set((state) => ({
          open: !state.open,
        }));
      },
      setOpen: (value: boolean) => {
        set(() => ({
          open: value,
        }));
      },

      setCompanyId: (companyId: number) => {
        set(() => ({
          companyId,
        }));
      },
    }),
    {
      name: "hd-notifications-store",
      partialize: (state) => ({ companyId: state.companyId }),
    }
  )
);
