import { useCallback, useRef, useState } from "react";
import { PiWarning } from "react-icons/pi";
import { useOnClickOutside } from "usehooks-ts";

import { useLastNoticeQuery } from "@hd/api/notice/queries/useLastNoticesQuery";
import HdLoader from "@hd/ui-kit/hd-loader/HdLoader";
import { formatRelativeToNow } from "@hd/utils/dates";

function Notices() {
  //   const { data, isPending } = useLastNoticeQuery();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const { data, isPending } = useLastNoticeQuery();

  const onClickOutside = useCallback(
    (e: MouseEvent | TouchEvent | FocusEvent) => {
      if (
        open &&
        !(e.target as HTMLElement).classList.contains("company-option")
      ) {
        setOpen(false);
      }
    },
    [open, setOpen]
  );

  useOnClickOutside(ref, onClickOutside);

  return (
    <div ref={ref} className="flex items-center relative group">
      <div
        className={"p-5 h-full cursor-pointer hover:bg-slate-50 "}
        onClick={() => setOpen(!open)}
      >
        <div className="relative">
          <PiWarning className="text-lg"></PiWarning>
          {data && data.filter((d) => !d.seen).length > 0 && (
            <div className="font-sans absolute inline-flex items-center justify-center px-1 min-w-4 h-4 text-xxs font-semibold text-white bg-accent rounded-full -top-3 -end-3">
              {data.filter((d) => !d.seen).length}
            </div>
          )}
        </div>
      </div>
      <div
        className={`absolute top-full mt-1 right-0 ${
          open ? "block" : "hidden"
        }`}
      >
        <div
          tabIndex={0}
          className="select-none align-center shadow-xl bg-white w-64 text-bodyText border-gray-200 border outline-none"
        >
          {!data || isPending ? (
            <HdLoader></HdLoader>
          ) : data.length === 0 ? (
            <div className="py-4 text-center px-3">
              Non sono presenti notifiche
            </div>
          ) : (
            <ul>
              {data.map((item) => (
                <li
                  key={item.noticeId}
                  className={`py-2 flex items-center justify-between border-b border-b-gray-300 w-full overflow-hidden even:bg-bodyBg`}
                >
                  <div className={`px-3 flex items-center w-full `}>
                    <div className={` flex-grow overflow-hidden`}>
                      <div
                        className="text-xs "
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      ></div>
                      <div className="text-xs font-bold">
                        {formatRelativeToNow(new Date(item.creationDate))}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notices;
