import clsx from "clsx";
import { formatRelative } from "date-fns";
import { it } from "date-fns/locale";
import { useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";

import { useSectionCompaniesQuery } from "@hd/api/company/queries/useSectionCompaniesQuery";
import CompanySelector from "@hd/auth/components/CompanySelector";
import { ContactPanelTabIds } from "@hd/contact-panel/types/contact-panel-tab-ids";
import { useContactPanel } from "@hd/contact-panel/useContactPanel";
import { useTranslate } from "@hd/i18n/useTranslate";
import { showOnlinePersonNotification } from "@hd/toast/toasts";
import { ContactConnectionInfoDto } from "@hd/types/dto/contact/contact-connection-info.dto";
import { SignalrChatMessageDto } from "@hd/types/dto/contact/signalr-chat-message.dto";
import { SectionIds } from "@hd/types/shared/section-ids";
import HdLoader from "@hd/ui-kit/hd-loader/HdLoader";

import useHubConnection from "../chat/useHubConnection";
import { useNotificationsStore } from "./notifications.store";

interface NotificationsProps {
  loggedUserName: string;
}

function Notifications({ loggedUserName }: NotificationsProps) {
  // const { items, setRead, setOpen, open, companyId, setCompanyId } =
  //   useNotifications();

  const { data, isPending } = useSectionCompaniesQuery({
    sectionId: SectionIds.ConversationHub,
  });

  const items = useNotificationsStore((s) => s.items);
  const push = useNotificationsStore((s) => s.push);
  const companyId = useNotificationsStore((s) => s.companyId);
  const setCompanyId = useNotificationsStore((s) => s.setCompanyId);
  const setRead = useNotificationsStore((s) => s.setRead);

  const { open: openContactPanel } = useContactPanel();

  const t = useTranslate();

  const companyName = useMemo(
    () => data?.find((c) => c.id === companyId)?.name,
    [data, companyId]
  );

  const onNewMessage = useCallback(
    (data: SignalrChatMessageDto) => {
      if (companyId && companyName) {
        if (data.UserType === 0) {
          push({
            type: "message",
            content: data,
            date: new Date(),
            read: false,
            companyName: companyName,
            companyId: companyId,
          });
        } else {
          // operator message
          // system message
        }
      }
    },
    [push, companyId, companyName]
  );

  const onMessagesRead = useCallback(
    (data: string[]) => {
      setRead(data);
    },
    [setRead]
  );

  const onPersonStatusUpdated = useCallback(
    (data: ContactConnectionInfoDto) => {
      if (data.PersonId != null && !data.IsClosed) {
        showOnlinePersonNotification("Nuovo utente online");
      }
    },
    []
  );

  useHubConnection(
    companyId,
    loggedUserName,
    onNewMessage,
    onPersonStatusUpdated,
    undefined,
    onMessagesRead
  );

  return (
    <div
      tabIndex={0}
      className="select-none align-center shadow-xl bg-white w-64 text-bodyText border-gray-200 border outline-none"
    >
      {isPending || !data ? (
        <HdLoader></HdLoader>
      ) : (
        <div className="px-3 flex items-center border-b-gray-200 border-b py-2">
          <span className="mr-1 text-xs">
            {t("notifications.chooseCompany")}
          </span>

          <div className="ml-auto">
            <CompanySelector
              activeCompanies={data}
              selectedCompanyId={companyId}
              selectedCompanyChanged={(companyId) => setCompanyId(companyId)}
            ></CompanySelector>
          </div>
        </div>
      )}
      <ul className="">
        {items.length === 0 ? (
          <div className="py-4 text-center px-3">
            {t("notifications.noNewMessages")}
          </div>
        ) : (
          items
            .filter((item) => item.companyId === companyId)
            .sort((a, b) => b.date.getTime() - a.date.getTime())
            .slice(0, 5)
            .map((item) => (
              <li
                key={item.date.getTime()}
                className={clsx(
                  `flex items-center justify-between border-b border-b-gray-300 w-full overflow-hidden hover:opacity-80 cursor-pointer even:bg-bodyBg`
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  const contactId = Number(
                    item.content.GroupName.split("-")[1]
                  );
                  const companyId = Number(
                    item.content.GroupName.split("-")[0]
                  );

                  openContactPanel(
                    contactId,
                    companyId,
                    ContactPanelTabIds.CONVERSATION
                  );
                }}
              >
                <div
                  className={clsx(
                    !item.read && "bg-[rgba(0,144,184,0.1)]",
                    `px-3 py-2 flex items-center w-full`
                  )}
                >
                  <div className={` flex-grow overflow-hidden`}>
                    <div className="text-xs font-bold">
                      {item.content.SenderFullName}
                    </div>
                    <div className="text-xs  text-ellipsis text-nowrap overflow-hidden">
                      {item.content.Text}
                    </div>
                    <div className="text-xs font-bold">
                      {formatRelative(item.date, new Date(), {
                        locale: it,
                      })}
                      {" - "}
                      {item.companyName}
                    </div>
                  </div>
                </div>
              </li>
            ))
        )}
      </ul>

      <div className="py-2 text-center px-3 bg-bodyBg">
        <NavLink
          className="underline text-xs"
          to={"/conversation-hub?companyId=" + companyId}
        >
          Vai all'hub conversazioni
        </NavLink>
      </div>
    </div>
  );
}

export default Notifications;
