import clsx from "clsx";
import { PropsWithChildren } from "react";
import { Dialog, Modal, ModalOverlay } from "react-aria-components";

interface HdModalProps {
  isOpen: boolean;
  width?: string;
  className?: string;
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  allowScroll?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  backdrop?: boolean;
}

function HdModal({
  className,
  isOpen,
  onOpenChange,
  children,
  backdrop,
  closeOnClickOutside,
  closeOnEsc,
}: PropsWithChildren<HdModalProps>) {
  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      isKeyboardDismissDisabled={!closeOnEsc}
      isDismissable={closeOnClickOutside}
      className={({ isEntering, isExiting }) => `
      fixed inset-0 z-10 overflow-y-auto flex min-h-full items-center justify-center p-4 
      ${isEntering ? "animate-in fade-in duration-300 ease-out" : ""}
      ${isExiting ? "animate-out fade-out duration-200 ease-in" : ""}
      ${backdrop ? "bg-black/25 backdrop-blur-sm" : ""}
    `}
    >
      <Modal
        className={clsx(
          " fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 outline-none  shadow-xl"
          // className
        )}
      >
        <Dialog
          className={clsx("outline-none", className)}
          role="dialog"
          aria-label="dialog"
        >
          {children}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}

export default HdModal;
