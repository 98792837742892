import httpClient from "@hd/http-client/http-client";

interface Params {
  groupId: number;
}

export const changeGroupRequest = async (data: Params) => {
  const res = await httpClient.post<{
    username: string;
    jwtToken: string;
  }>(
    import.meta.env.VITE_HD_API_URL + "/auth/change-group/" + data.groupId,
    {}
  );

  return { jwtToken: res.data.jwtToken };
};
